<template>
	<div class="formulaire_jira_detail">
		<div v-if="!load">
			<center>
				<v-progress-circular
					:size="180"
					color="#fdbe45"
					indeterminate
				>
					<span style="font-size: 25px">
						Chargement
					</span>
				</v-progress-circular>				
			</center>
		</div>
		<div v-if="load">
			<div>
				<router-link to="/jira-suivi" class="back-menu">
					&lt; Retour aux demandes
				</router-link>
			</div>
			<h2 class="mt-3"><a :href="'https://novaseo.atlassian.net/browse/' + ref" target="_blank" title="Consulter cette demande sur Jira">[{{ ref }}] {{ demande.fields.summary}}</a></h2>
			<v-row class="mt-10">
				<v-col col="12" md="6" class="rendu_html">
					<div v-html="demande.renderedFields.description"></div>
				</v-col>
				<v-col col="12" md="5" offset="0" offset-md="1" class="attribus">
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Status</v-col>
						<v-col>
							<v-chip label small dark :color="getColor(demande.fields.status.name)">
								{{ demande.fields.status.name }}
							</v-chip>
						</v-col>
					</v-row>
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Crée le</v-col>
						<v-col>
							{{ demande.renderedFields.created }}
						</v-col>
					</v-row>
					<v-row v-for="obj in objectifs" v-bind:key="obj.id">
						<v-col col="4" md="4" class="label-attribus">{{ labelDelay(obj.name) }}</v-col>
						<v-col class="objectif">
							<div v-if="obj.completedCycles.length != 0">								 
								<span v-if="obj.completedCycles[obj.completedCycles.length - 1].breached">
									<v-icon color="success">mdi-check</v-icon> en retard <span v-bind:class="delayClass(obj.completedCycles[obj.completedCycles.length - 1])">{{ formatDateText(obj.completedCycles[obj.completedCycles.length - 1].remainingTime.friendly)}}</span>
								</span>
								<span v-else>
									<v-icon color="success">mdi-check</v-icon>
								</span>
							</div>
							<div v-else-if="obj.ongoingCycle != {}">
								<span v-if="obj.ongoingCycle.breached">Retard de </span>
								<span v-else>Reste </span>
								<span v-bind:class="delayClass(obj.ongoingCycle)">{{ formatDateText(obj.ongoingCycle.remainingTime.friendly) }}</span>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Priorité</v-col>
						<v-col>
							<img :src='demande.fields.priority.iconUrl' style="width:16px;padding-top: 5px;">
							{{ demande.fields.priority.name }}
						</v-col>
					</v-row>
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Créateur</v-col>
						<v-col v-if="demande.fields.creator != null">
							<v-avatar size="26">
								<img :src='demande.fields.creator.avatarUrls["16x16"]'>
							</v-avatar>
							{{ demande.fields.creator.displayName }}
						</v-col>
						<v-col v-else>
							NC
						</v-col>
					</v-row>
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Rapporteur</v-col>
						<v-col v-if="demande.fields.reporter != null">
							<v-avatar size="26">
								<img :src='demande.fields.reporter.avatarUrls["16x16"]'>
							</v-avatar>
							{{ demande.fields.reporter.displayName }}
						</v-col>
						<v-col v-else>
							NC
						</v-col>
					</v-row>
					<v-row>
						<v-col col="4" md="4" class="label-attribus">Rapporteur Interne</v-col>
						<v-col v-if="demande.fields.customfield_10062 != null">
							<v-avatar size="26">
								<img :src='demande.fields.customfield_10062.avatarUrls["16x16"]'>
							</v-avatar>
							{{ demande.fields.customfield_10062.displayName }}
						</v-col>
						<v-col v-else>
							NC
						</v-col>
					</v-row>
					<v-row class="mb-10">
						<v-col col="4" md="4" class="label-attribus">Assignation Interne</v-col>
						<v-col v-if="demande.fields.customfield_10059 != null">
							<v-avatar size="26">
								<img :src='demande.fields.customfield_10059.avatarUrls["16x16"]'>
							</v-avatar>
							{{ demande.fields.customfield_10059.displayName }}
						</v-col>
						<v-col v-else>
							NC
						</v-col>
					</v-row>
					<v-btn depressed @click="dialog = true">Commentaires ({{ demande.fields.comment.comments.length }})</v-btn>				
				</v-col>
			</v-row>	
		</div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar dark color="#313131" fixed>
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Commentaires</v-toolbar-title>
				</v-toolbar>
				<div class="pa-8 comment-layout" v-if="load">
					<v-form 
						v-model="opt_form.valide"
						mode="aggressive"
						ref="form"
					>
						<v-textarea
							outlined
							name="input-7-4"
							label="Ajouter un commentaire"
							v-model="commentaire"
							:rules="opt_form.rules.commentaire"
							class=""
						></v-textarea>
						<InputFileUpload
							colorBack="#3d2f59"
							class="mb-4"
							@change="files_changes"
						></InputFileUpload>
						<v-btn depressed :disabled="!opt_form.valide" @click="send">Envoyer</v-btn>
					</v-form>					
					<div class="mt-10"></div>
					<div v-for="x in demande.renderedFields.comment.comments" v-bind:key="x.id" class="comment mb-5">
						<div class="comment-header">
							<v-avatar size="36" class="mr-2">
								<img :src="x.author.avatarUrls['16x16']">
							</v-avatar>
							<b class="mr-2">{{ x.author.displayName }}</b> {{ x.updated }}
							<div class="mt-8" v-html="x.body"></div>
						</div>                          
					</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions, mapMutations } from 'vuex';
	import InputFileUpload from '../InputFileUpload.vue';

	export default {
		name: 'Jira',
		components : {
			InputFileUpload
		},
		data: () => ({
			ref: "",
			dialog: false,
			demande: {},
			objectifs: [],
			first_reponse: {
				delais: null,
				restant: null
			},
			resolution: {
				delais: null,
				restant: null
			},
			load: false,
			files: [],
			commentaire:"",
			opt_form: {
				lazy: false,
				valide: false,
				rules : {
					commentaire : [
						v => v != "" ? true : 'Le commentaire est requis',
						v => v.length > 5 ? true : "Le commentaire est trop court"
					]
				}
			}
		}),
		created: function() {
			var _this = this;
			this.ref = this.$route.params.ref
			this.getJiraDemandes(this.ref)
			.then(x => {
				x.renderedFields.description = this.addNddLiens(x.renderedFields.description)

				for (let index = 0; index < x.renderedFields.comment.comments.length; index++) {
					x.renderedFields.comment.comments[index].body = this.addNddLiens(x.renderedFields.comment.comments[index].body)
				}

				_this.demande = x
		
				return _this.getJiraSla(_this.ref)
			})
			.then(x => {
				this.objectifs = x["values"]				
			})
			.finally(() => {
				_this.load = true;
			})
		},
		methods: {
			...mapActions('formulaires', [
				"getJiraDemandes",
				"getJiraSla",
				"sendCommentaire"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			addNddLiens(text) {
				text = text.replace('href="/rest/api/', 'href="https://novaseo.atlassian.net/rest/api/');
				text = text.replace('src="/rest/api/', 'src="https://novaseo.atlassian.net/rest/api/');

				return text
			},
			getColor(status) {
				if (status == "Termine") return 'success'
				if (status == "Ouvert") return 'info'
				else return 'secondary'
			},
			delayClass: function(x) {
				if(x.breached) {
					return "ko"
				}
				
				return "ok"
			},
			labelDelay: function(x) {
				if(x == "Time to first response") {
					return "Délais 1ére réponse"
				}
				
				if(x == "Time to resolution") {
					return "Délais résolution"
				}

				return x
			},
			formatDateText: function(x) {
				var text = x.replace("-", "")
				return text.replace(" ","")
			},
			files_changes: function(e) {
				this.files = e;
			},
			send: function() {
				var _this = this;

				this.sendCommentaire({
					cab: this.demande.fields.customfield_10055,
					ref: this.ref,
					commentaire: this.commentaire,
					files: this.files.map(x => {
						return x.file_id
					})
				})
				.then(() => {
					_this.sendSuccessNotif("Commentaire envoyé")
					_this.dialog = false
				})
			}
		}
	};
</script>

<style lang="scss">
	.formulaire_jira_detail {
		width: 90%;
		border-radius: 16px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.mt-3{
			a {
				color: unset;
				text-decoration: none;
			}
		}

		.back-menu
		{
			color: black;
			text-decoration: none;
		}

		h2 {
			width: auto;
			display:inline-block;
			text-transform: uppercase;
			border-bottom:4px solid rgb(253, 190, 69);
		}

		.rendu_html
		{
			overflow-x: hidden;
		}

		.objectif
		{
			.ko
			{
				color: red;
				font-weight: bold;
			}

			.ok
			{
				color: green;
				font-weight: bold;
			}
		}

		.attribus
		{
			.label-attribus
			{
				font-weight: bold;
			}
		}
	}

	.comment-layout
	{
		width: 60%;
		margin-left: auto;
		margin-right: auto;

		.comment
		{
			background: rgb(255, 250, 230);
			padding: 20px;
		}

		textarea
		{
			width: 100%;
			min-height: 150px;
		}
	}

	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire_jira_detail
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire_jira_detail
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire_jira_detail
		{
			width: 90%;
		}
	}
</style>