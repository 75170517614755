<template>
	<div class="InputFileUpload">
		<div class="in">
			<label class="upload" v-bind:style="{'border-color': colorBack}">
				<v-icon :color="colorBack" class="pr-2">mdi-upload</v-icon>
				<span>Téléchargez vos fichiers ici</span>
				<input id="input_files" class="input_file" type="file" ref="myFiles" @change="send" multiple>
			</label> 
			<div class="files_list">
				<v-chip 
					class="mr-2 mb-4 file_resume" 
					close
					outlined 
					v-for="(f, index) in files" 
					v-bind:key="index"
					v-show="f.error == false && f.cancelled == false"
					@click:close="remove(index)"
					:color="colorLabel(index)"
				>
					<div v-if="f.load">
						<v-icon left v-if="f.file.type == 'application/pdf'">mdi-file-pdf-box</v-icon>
						<v-icon left v-else-if="f.file.type.indexOf('image') != -1">mdi-image</v-icon>
						<v-icon left v-else>mdi-file</v-icon>						
					</div>
					<div v-else>
						<v-progress-circular indeterminate :size="20" :width="3" class="mr-3"></v-progress-circular>
					</div>
					{{ format_text(f.file.name, 20) }}
				</v-chip>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import axios from 'axios'

	export default {
		name: 'InputFileUpload',
		props: ['colorBack'],
		data: () => ({
			files: [],
			loading: false,
			error_message: []
		}),
		computed: {

		},
		beforeDestroy: function() {
			for (var i = 0; i < this.files.length; i++) {
				if(!this.files.load) {
					this.files[i].cancel.cancel();
				}
			}
		},
		methods: {
			...mapActions('formulaires', [
				"sendFile",
			]),
			sendEventChange: function(){
				var data = this.files.filter(x => {
					return x.vals != null 
						&& x.vales != {}
						&& x.cancelled == false
						&& x.error == false
				}).map(x => {
					return x.vals
				})

				this.$emit('change', data)
			},
			colorLabel: function(i) {
				if(this.files[i].cancelled || this.files[i].error) {
					return "red";
				}

				return "secondary";
			},
			remove: function(i) {
				if(!this.files[i].load) {
					this.files[i].cancel.cancel();
				} else {
					this.files.splice(i,1);
					this.sendEventChange();
				}				
			},
			format_text: function(str, n) {
				return (str.length > n) ? str.substr(0, n-1) + '...' : str;
			},
			send: function() {
				this.error_message = [];
				this.files = Array.from(this.$refs.myFiles.files).map(x => {
					return {
						load: false,
						file: x,
						vals: null,
						cancel : axios.CancelToken.source(),
						end : false,
						error: false,
						cancelled: false
					}
				}).concat(this.files)



				for(var i = 0; i < this.files.length; i++) {
					if(!this.files[i].load) {
						this.sendOneFile(i);
					}					  
				}

				this.$refs.myFiles.value = ""
			},
			sendOneFile: function(index) {
				var formData = new FormData();
				formData.append("file", this.files[index].file);
				var _this = this;

				this.sendFile({
					formData: formData,
					name: this.files[index].file.name,
					cancel: this.files[index].cancel
				})
				.then(response => {
					_this.files[index].load = true;
					_this.files[index].vals = response

					if(axios.isCancel(response)) {
						_this.files[index].cancelled = true;
						return
					}

					if(response.url == undefined) {
						_this.files[index].error = true;
					}					
				})
				.finally(() => {
					_this.sendEventChange()
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.in {
		width: 100%;
		text-align: center;

		.infos_import {
			display: block;
			color: white;
		}

		.upload {
			border-radius: 5px;
			height: 30px;
			padding: 20px;
			border: 2px dashed #aaaaaa;
			display: flex;
			margin: 0px auto;
			justify-content: left;
			align-items: center;
			cursor: pointer; 
			background: #f3f3f3;
			margin-bottom: 20px;

			&:hover {
				border-color: #d1d1d1;
			}

			input {
				display: none;
			}

			span {
				color: black;
			}

			svg {
				margin: 0 10px;
				color: blue;
			}
		}

		.files_list
		{
			text-align: left;

			.file_resume {
				max-width: 250px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>